<script setup>
</script>

<template>
     
  <slot />
  <PageMainAiAssistant v-if="!('/college/result')" />
</template>

<style scoped lang="scss">
</style>
